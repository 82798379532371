import { useQuery } from '@tanstack/react-query';
import { CommonConstant } from 'constants/code.constant';
import ProjectRepository from 'repositories/ProjectRepository';
import CommonUtil from 'utils/common.util';

const useProject = () => {
  const useGetProjectList = ({ page = 1, limit = 10, sortBy, sortOrder, name, orderer, executionYear }) => {
    const getProjectList = () => {
      const params = {
        offset: (page - 1) * limit,
        limit,
        sortBy,
        sortOrder,
      };

      if (name) {
        params.name = name;
      }

      if (orderer) {
        params.orderer = orderer;
      }

      if (executionYear) {
        params.executionYear = executionYear;
      }

      return ProjectRepository.getProjectList(params)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const projectListQuery = useQuery({
      queryKey: ['projectList', { page, sortBy, sortOrder }],
      queryFn: getProjectList,
    });

    return {
      list: projectListQuery.data?.list || [],
      totalCount: projectListQuery.data?.totalCount || 0,
      refetch: projectListQuery.refetch,
    };
  };

  const useGetProject = (projectId) => {
    const getProject = () => {
      return ProjectRepository.getProject(projectId)
        .then((res) => {
          if (res.result === CommonConstant.OK) {
            return res.data;
          } else {
            console.error(res);
          }
        })
        .catch(CommonUtil.repoErrFunc);
    };

    const projectQuery = useQuery({
      queryKey: ['project', projectId],
      queryFn: getProject,
      // projectId가 있을 때만 쿼리 실행
      enabled: !!projectId,
    });

    return {
      projectInfo: projectQuery.data || null,
      refetch: projectQuery.refetch,
    };
  };

  return { useGetProjectList, useGetProject };
};

export default useProject;
