import { CommonConstant } from 'constants/code.constant';
import { ErrorCodeConstant } from 'constants/errorCode.constant';
import MainLayout from 'layouts/MainLayout';
import { useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import AccountRepository from 'repositories/AccountRepository';
import CommonUtil from 'utils/common.util';

const AdminAccountBulkUpload = () => {
  const fileRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleFileUploadClick = () => {
    fileRef.current.click();
  };

  const handleFileUpload = (e) => {
    if (isLoading) {
      alert('업로드 중입니다. 잠시만 기다려주세요');
      return;
    }

    const file = e.target.files[0];

    e.target.value = '';

    if (!file) {
      return;
    }

    if (file.name.split('.').pop().toLowerCase() !== 'xlsx') {
      alert('확장자가 .xlsx 파일만 업로드할 수 있습니다.');
      return;
    }

    setIsLoading(true);

    AccountRepository.saveBulkAccount({ xlsxFile: file })
      .then((res) => {
        if (res.result === CommonConstant.OK) {
          const { insertCount, updateCount } = res.data;
          alert(`업로드를 완료하였습니다. 등록된 개수: ${insertCount} 수정된 개수: ${updateCount}`);
          window.location.reload();
        }

        if (res.result === CommonConstant.ERROR) {
          const errorCode = res.errorCode;
          if (errorCode === ErrorCodeConstant.ERROR_CODE.NO_REQUIRED_PARAM) {
            alert('필수 헤더 또는 필수 항목 값이 없습니다.');
          } else if (errorCode === ErrorCodeConstant.ERROR_CODE.INVALID_ROLE_TYPE) {
            alert('유효하지 않는 권한 유형 값이 있습니다.');
          } else if (errorCode === ErrorCodeConstant.ERROR_CODE.HAS_DUPLICATE_EMAIL_OR_COMPANY) {
            alert('엑셀 파일 내에 담당자 이메일 또는 기업명에 중복된 값이 었습니다');
          } else if (errorCode === ErrorCodeConstant.ERROR_CODE.DUPLICATE_COMPANY) {
            alert('엑셀 파일 내에 기존 기업명과 중복된 값이 있습니다.');
          } else {
            console.error(res);
            alert('알 수 없는 에러 코드');
          }
        }
      })
      .catch(CommonUtil.repoErrFunc)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainLayout>
      <div className="page-header">기업, 컨설턴트 대량 업로드</div>
      <div className="page-card">
        <b>
          1. 전체 항목 (상단 헤더 이름은 다음과 일치해야 합니다. 일치하지 않는 경우 해당 값은 제외되고 업로드됩니다.
          굵은 항목은 필수 항목이므로 값이 없는 경우 전체 업로드되지 않습니다.)
        </b>
        <p>
          <b> - 권한 유형, 담당자 이메일, 담당자 이름,</b> 소속명 | 기업명, 기업형태, 소속 | 기업 연락처, 등급,
          대표자명, 대표자 이메일, 대표자 전화번호, 대표자 성별, 대표자 생년월일, 공동 대표자, 담당자 연락처, 담당자
          직급, 기업현황, 사업자번호, 사업자등록일, 법인등록번호, 법인설립일, 홈페이지 링크, 우편번호, 주소, 상세 주소,
          분야, 주요생산품, 인수합병일, 휴업일, 폐업일, 벤처인증번호, 벤처인증일, 비고, 특화분야, 은행명, 계좌번호,
          주민등록번호
        </p>
        <br />
        <br />
        <b>2. 권한 유형 값 (다음과 같은 값이 없는 경우 전체 업로드되지 않습니다.)</b>
        <p> - 기업, 보육사, 전문가, 파트너 중 1개 필수</p>
        <br />
        <br />
        <p>
          <b>
            3. 담당자 이메일, 소속명 | 기업명은 고유 값이므로 같은 데이터가 2개 이상 있는 경우 전체 업로드 되지
            않습니다. (단, 권한 유형이 전문가, 파트너인 경우 소속명 | 기업명이 중복되어도 상관 X)
          </b>
        </p>
        <br />
        <br />
        <p>
          <b>
            4. 기존 항목과 새로운 항목은 담당자 이메일로 비교됩니다. 기존 담당자 이메일이 있는 경우 값이 수정되고, 없는
            경우 새로 추가됩니다.
          </b>
        </p>
        <br />
        <br />
        <p>
          <b>5. 다중 선택이 있는 경우 ,로 구분해주세요 (ex. 회계, 인사/노무, 브랜딩/BM)</b>
        </p>
        <br />
        <br />
        <input ref={fileRef} type="file" accept=".xlsx" onChange={handleFileUpload} hidden />
        <button className="btn success-btn" onClick={handleFileUploadClick}>
          파일 업로드
          {isLoading && ' 중'}
        </button>
        {isLoading && (
          <>
            <br />
            <br />
            <div className="loading">
              <ClipLoader />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default AdminAccountBulkUpload;
