import { API_ACCOUNT_ROUTE } from 'constants/api.constant';
import AxiosUtil from 'utils/axios.util';

const AccountRepository = {
  getAccountList(data) {
    try {
      return AxiosUtil.get(
        { url: `${API_ACCOUNT_ROUTE}/list`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  getAccount(code) {
    try {
      return AxiosUtil.get(
        { url: `${API_ACCOUNT_ROUTE}/${code}`, submitData: {} },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveAccount(data) {
    try {
      return AxiosUtil.post(
        { url: `${API_ACCOUNT_ROUTE}`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },

  saveBulkAccount(data) {
    try {
      return AxiosUtil.mixedDataUpload(
        { url: `${API_ACCOUNT_ROUTE}/bulk`, submitData: data },
        (res) => {
          return res;
        },
        (err) => {
          console.error(err.message);
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  },
};

export default AccountRepository;
